import React from "react"
import { PhoneIcon, InboxIcon } from '@heroicons/react/outline'
import Seo from '../components/seo'
import Layout from '../components/layout'
import Footer from '../components/footer'

import axios from "axios"
import { Formik, Field, Form } from 'formik'
import { PhoneMask } from '../forms/components'

import '../styles/forms.css'

const encode = (data) => {
  return Object.keys(data)
  .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
  .join("&")
}

const post_form = async (values) => {
  return await axios({
    method: 'POST',
    url: '/',
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    data: encode({ "form-name": "contact", ...values })
  })
  .then(() => (true))
  .catch(err => {
    alert(err.message)
    return false
  })
}

const ContactForm = () => {
	return (
    <div className="w-full lg:w-4/5">
      <Formik
        initialValues={{
          first: '',
          last: '',
          company: '',
          email: '',
          phone: '',
          comments: ''
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(post_form(values))
        }}
      >
        {({ isSubmitting, errors, values }) => {
          const default_btn_class = "btn btn-primary"
          const btn_class = isSubmitting ? (default_btn_class + " disabled") : default_btn_class
          return (
            <Form className="pb-28">
              <div className="pb-12 pt-6 text-xl md:text-2xl">
                <div className="w-full pb-4">
                  <PhoneIcon className="inline-block w-12 h-12" /> <a className="hover:text-seagreen" href="tel:17346375156">(734) 637-5156</a>
                </div>
                <div className="w-full pb-4">
                  <InboxIcon className="inline-block w-12 h-12" /> <a className="hover:text-seagreen" href="mailto:andrew.j.wagoner@gmail.com">andrew.j.wagoner@gmail.com</a>
                </div>
              </div>
              {
                isSubmitting ?
                <div className="bg-aquamarine border border-charcoal text-charcoal px-4 py-3 mb-6 rounded" role="alert">
                  <span className="block sm:inline">Thanks! We shall be contacting you soon for a brief consult.</span>
                </div>
                : null
              }
              <div className="md:items-center mb-6">
                <div className="w-full">
                  <label className="block text-purple-dark font-bold mb-1 pr-4" htmlFor="field-first">
                    First Name *
                  </label>
                </div>
                <div className="w-full">
                  <Field className="input-field" id="field-first" name="first" type="text" required />
                </div>
              </div>
              <div className="md:items-center mb-6">
                <div className="w-full">
                  <label className="block text-purple-dark font-bold mb-1 pr-4" htmlFor="field-last">
                    Last Name *
                  </label>
                </div>
                <div className="w-full">
                  <Field className="input-field" id="field-last" name="last" type="text" required />
                </div>
              </div>
              <div className="md:items-center mb-6">
                <div className="w-full">
                  <label className="block text-purple-dark font-bold mb-1 pr-4" htmlFor="field-company">
                    Company Name
                  </label>
                </div>
                <div className="w-full">
                  <Field className="input-field" id="field-company" name="company" type="text" />
                </div>
              </div>
              <div className="md:items-center mb-6">
                <div className="w-full">
                  <label className="block text-purple-dark font-bold mb-1 pr-4" htmlFor="field-email">
                    E-mail *
                  </label>
                </div>
                <div className="w-full">
                  <Field className="input-field" id="field-email" name="email" type="email" placeholder="jane.doe@company.com" required />
                </div>
              </div>
              <div className="md:items-center mb-6">
                <div className="w-full">
                  <label className="block text-purple-dark font-bold mb-1 pr-4" htmlFor="field-phone">
                    Phone *
                  </label>
                </div>
                <div className="w-full">
                  <Field className="input-field" id="field-phone" name="phone" component={PhoneMask} />
                </div>
              </div>
              <div className="md:items-center mb-6">
                <div className="w-full">
                  <label className="block text-purple-dark font-bold mb-1 pr-4" htmlFor="field-comments">
                    Questions/Comments
                  </label>
                </div>
                <div className="w-full">
                  <Field className="input-field" rows="8" id="field-comments" name="comments" component="textarea" />
                </div>
              </div>
              <div className="md:items-center">
                <div className="w-full">
                  <button className={btn_class} type="submit" disabled={isSubmitting}>
                    Submit
                  </button>
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>
    </div>
	)
}

export default function Contact() {
  const page_title = "Contact Me"
	return (
  	<>
  		<Seo title={page_title} slug="contact" />
	  	<Layout>
	  		<main className="mt-16 mx-auto max-w-7xl px-4 sm:px-6 md:mt-16 lg:px-8">
          <div className="sm:text-center lg:text-left">
            <h2 className="text-3xl tracking-tight font-extrabold text-charcoal sm:text-4xl md:text-5xl py-6">
              <span className="block xl:inline">{page_title}</span>
            </h2>
          </div>
	  			<ContactForm />
	  		</main>
	  	</Layout>
	  	<Footer />
	  </>
	)
}
import React from "react"
import { Link } from "gatsby"
import { navigation, get_y_coords } from './shared'

const Footer = () => (
	<footer className="border-t-4 border-charcoal text-white bg-seagreen py-16">
  	<div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
  		<div className="flex flex-wrap justify-center lg:justify-end">
        <div className="w-full lg:w-1/2 flex flex-col items-center lg:items-start pb-10">
          <h3 className="text-xl font-semibold pb-4 text-charcoal">Navigation</h3>
          <ul>
          { navigation.map(({ name, href }) => (
            <li key={name} className="py-1 text-center lg:text-left">
            	{
            		href.includes('#') ?
            		<a href={href} className="hover:text-charcoal" onClick={e => {
                  e.preventDefault()
                  if (typeof window !== `undefined`) {
	                  window.scrollBy({
	                    top: get_y_coords(href),
	                    behavior: 'smooth'
	                  })
	                }
                }}>{name}</a> :
            	<Link className="hover:text-charcoal" to={href}>{name}</Link>
            	}
            </li>
          )) }
          </ul>
        </div>
  			<div className="w-full lg:w-1/2 flex flex-col items-center lg:items-end">
	  			<div className="pt-4 text-charcoal"><span className="font-semibold">&copy;{new Date().getFullYear()}</span> <a href="/" className="text-white hover:text-charcoal">Lakepointe Group, LLC</a></div>
	  		</div>
  		</div>
  	</div>
  </footer>
)

export default Footer